var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('nav', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_c('profile-header')], 1), _c('section', {
    attrs: {
      "id": "profile-info"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "cols": "12"
    }
  }), _c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_c('pembayaran', {
    attrs: {
      "data": _vm.data
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "cols": "12"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "12",
      "order": "4"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }