<template>
  <section id="card-images">
    <b-overlay :show="loading">
      <b-row>
        <b-col>
          <div
            class="mt-3 mb-2 text-primary"
            align="center"
            text-color="primary"
          >
            <b-card
              :img-src="apiFile + data.photo"
              img-left
              img-width="253px"
              img-max-width="100%"
              img-height="auto"
              img-alt="card img"
              class="mb-2"
              style="padding: 20px"
              no-body
            >
              <b-col>
                <b-card-title align="left" style="font-size: 1.5rem"
                  ><b>{{ data.name }} </b></b-card-title
                >
                <b-card-text align="left">
                  <p class="text-dark" v-html="data.description"></p>
                </b-card-text>
                <b-card-text class="mt-4" align="left" style="font-size: 2rem">
                  <b>Rp{{ formatRupiah(data.price) }}</b>
                </b-card-text>
              </b-col>
              <!-- <b-card-body> -->
              <!--  -->
              <!-- </b-card-body> -->
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col align="center">
          <h5><b>Pilih Metode Pembayaran</b></h5>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!-- <b-tabs content-class="pt-1" justified>
          <b-tab :title="item.group" v-for="(item, i) in payment_method" :key="i">
            <b-form-group v-slot="{ ariaDescribedby }">
              <div class="" v-for="(data, index) in item.items" :key="index">
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" :value="data"
                  ><b-img :src="data.icon_url" width="100px" class="mr-2"></b-img> <b>{{ data.name }}</b></b-form-radio
                >
                <hr />
              </div>
            </b-form-group>
          </b-tab>
        </b-tabs> -->
          <b-card>
            <app-collapse accordion>
              <app-collapse-item
                :title="item.group"
                v-for="(item, i) in payment_method"
                :key="i"
                class=""
              >
                <b-form-group v-slot="{ ariaDescribedby }">
                  <div
                    class=""
                    v-for="(data, index) in item.items"
                    :key="index"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                      @click="selectPilihan(data, index)"
                      style="cursor: pointer"
                    >
                      <div class="d-flex align-items-center">
                        <b-img
                          :src="data.icon_url"
                          width="100px"
                          class="mr-2"
                        ></b-img>
                        <b>{{ data.name }}</b>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selected"
                          :value="data"
                          name="flavour-3a"
                        >
                        </b-form-radio>
                      </div>
                    </div>
                    <hr />
                  </div>
                </b-form-group>
              </app-collapse-item>
            </app-collapse>
          </b-card>
          <!-- </b-row> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="mt-3 mb-2">
            <b-card>
              <div>
                <!-- <b-col> -->
                <b-card-title><b>Ringkasan Pembayaran </b></b-card-title>
                <b-card-text>
                  <b-row>
                    <b-col md="9"> Harga Paket </b-col>
                    <b-col md="3" align="right"
                      >Rp{{ formatRupiah(data.price) }}
                    </b-col>
                  </b-row>
                  <b-row v-if="diskon" class="mt-1">
                    <b-col md="9">
                      <h5 class="text-primary">Diskon {{ voucher_name }}</h5>
                    </b-col>
                    <b-col md="3" align="right">
                      <h5 class="text-primary">
                        -Rp{{ formatRupiah(potongan) }}
                      </h5>
                    </b-col>
                  </b-row>
                  <hr />
                </b-card-text>
                <b-card-text>
                  <div class="mt-2 mb-2">
                    <b-row>
                      <b-col md="9"><b>Total Harga</b></b-col>
                      <b-col md="3" align="right"
                        ><b
                          >Rp{{
                            total_harga == null
                              ? formatRupiah(data.price)
                              : formatRupiah(total_harga)
                          }}
                        </b></b-col
                      >
                    </b-row>
                  </div>
                </b-card-text>
                <b-card-text>
                  <div class="mt-2 mb-2">
                    <b-row>
                      <b-col>
                        <b-button
                          block
                          variant="outline-primary"
                          v-b-modal.modal-lg
                          @click="ModalShow()"
                        >
                          <b-row v-if="diskon">
                            <b-col align="right" md="1"
                              ><feather-icon
                                icon="CheckCircleIcon"
                                class="mr-2"
                            /></b-col>
                            <b-col align="left" md="10"
                              >Pakai voucher lebih hemat</b-col
                            >
                            <b-col align="right" md="1"
                              ><feather-icon icon="ArrowRightIcon" /></b-col
                          ></b-row>
                          <b-row v-else>
                            <b-col align="right" md="1"
                              ><feather-icon icon="PercentIcon" class="mr-2"
                            /></b-col>
                            <b-col align="left" md="10"
                              >Pakai voucher lebih hemat</b-col
                            >
                            <b-col align="right" md="1"
                              ><feather-icon icon="ArrowRightIcon" /></b-col
                          ></b-row>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-modal
                    v-model="Modal"
                    id="modal-tambah"
                    centered
                    title="Pakai Voucher"
                    hide-footer
                  >
                    <b-card-text>
                      <b-form>
                        <b-row v-if="voucher_name == ''">
                          <b-col md="9">
                            <b-form-group>
                              <b-form-input
                                v-model="voucher_name"
                                placeholder="Masukan voucher"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-button
                              variant="primary"
                              block
                              @click="pakaiVoucher()"
                            >
                              Pakai
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row v-else>
                          <b-col md="9">
                            <b-form-group>
                              <b-input-group>
                                <b-form-input
                                  v-model="voucher_name"
                                  placeholder="Masukan voucher"
                                />
                                <b-input-group-append>
                                  <b-button
                                    @click="
                                      (voucher_name = ''),
                                        (selected_voucher = null),
                                        (total_harga = null),
                                        (diskon = false)
                                    "
                                    variant="outline-primary"
                                  >
                                    <feather-icon
                                      icon="XIcon"
                                      class="cursor-pointer"
                                    />
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-button
                              variant="primary"
                              block
                              @click="pakaiVoucher()"
                            >
                              Pakai
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                    <hr />
                    <b-card-text> Atau pilih voucher </b-card-text>
                    <b-card-text
                      class="mb-2"
                      v-for="(data, index) in listvoucher"
                      :key="index"
                    >
                      <b-button
                        @click="
                          selected_voucher = data;
                          voucher_name = data.voucher.name;
                        "
                        variant="outline-primary"
                        block
                      >
                        <b-row
                          >Diskon
                          {{
                            data.voucher.type == "nominal"
                              ? formatRupiah(data.voucher.value)
                              : data.voucher.value + "%"
                          }}</b-row
                        >
                        <b-row class="mt-1 text-dark">{{
                          data.voucher.name
                        }}</b-row>
                        <b-row class="mt-1 text-secondary"
                          ><feather-icon icon="CalendarIcon" class="mr-1" />
                          Berlaku dari
                          {{ humanDate(data.voucher.start_time) }} hingga
                          {{ humanDate(data.voucher.end_time) }}</b-row
                        >
                      </b-button>
                    </b-card-text>
                  </b-modal>
                </b-card-text>
                <b-card-text>
                  <div class="mt-2 mb-2">
                    <b-row>
                      <b-col>
                        <b-button block variant="primary" @click="lanjutkan()"
                          >Lanjutkan Pembayaran</b-button
                        >
                      </b-col>
                    </b-row>
                  </div>
                </b-card-text>

                <!-- </b-col> -->
                <!--  -->
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BImg,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BTabs,
    BTab,
    BImg,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    vSelect,
    AppCollapse,
    AppCollapseItem,
  },
  props: ["data"],
  data() {
    return {
      id: null,
      search: "",
      activeAction: null,
      payment_method: [],
      voucher_name: "",
      selected_voucher: null,
      titleModal: null,
      voucher: 0,
      potongan: 0,
      selected: null,
      tipe_payment: null,
      total_harga: null,
      Modal: false,
      diskon: false,
      tipePayment: [
        { value: "payment_gateway", text: "Payment Gateway" },
        { value: "manual", text: "Manual" },
      ],
      options: [
        { text: "First radio", value: "first" },
        { text: "Second radio", value: "second" },
        { text: "Third radio", value: "third" },
      ],
      listvoucher: [],
      loading: false,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    voucher_name(val) {
      this.getVoucher();
    },
  },
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
    ebookDetail() {
      return this.$store.state.ebook.detail;
    },
  },
  methods: {
    selectPilihan(data, i) {
      this.selected = data;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah payment_method";
      this.Modal = true;
      // this.get();
      // this.ModalEdit = false;
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },

    getPayment() {
      this.loading = true;
      this.$store
        .dispatch("payment/pMethod")
        .then((response) => {
          this.loading = false;
          let payment_method = response.data.data;
          this.payment_method = payment_method;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    getVoucher() {
      let params = {
        // search: this.voucher_name,
        ebook_id: this.ebookDetail.id,
      };
      this.$store
        .dispatch("voucher/ebook", params)
        .then((response) => {
          let listvoucher = response.data.data;
          this.listvoucher = listvoucher;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },

    lanjutkan() {
      this.loading = true;
      if (this.tipe_payment == null && this.selected == null) {
        this.loading = false;
        this.pesanGagal();
        return false;
      }
      let order_items = {
        id: this.data.id,
        name: this.data.name,
        price: this.data.price,
        slug: this.data.slug,
      };

      let payload = {
        code_payment_method: this.selected.code,
        amount: this.data.price,
        type_order: "ebook",
        type_payment: this.selected.type_payment,
        order_items: [order_items],
      };
      if (this.selected_voucher) {
        payload.voucher_id = this.selected_voucher.voucher_id;
      }

      this.$store
        .dispatch("e-book/checkout", payload)
        .then((res) => {
          this.loading = false;
          this.$store.commit("payment/SET_DETAIL", this.selected);
          this.selected = "";
          this.displaySuccess({
            text: "Silahkan Lanjutkan untuk Konfirmasi Pembayaran E-book",
          });

          // save response to state
          const transaction = res.data;
          const isManualTransfer = this.selected?.type_payment === "manual";

          transaction.order_id = transaction?.data?.order_id;
          transaction.total_harga = !transaction.total_harga
            ? this.data.price
            : this.total_harga;
          this.$store.commit("payment/SET_CURRENT_TRANSACTION", transaction);

          this.$router.push({
            name: "payment-ebook-confirmation",
            params: {
              id: this.data.slug,
              obj: transaction,
            },
          });
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },

    pakaiVoucher() {
      const voucherPrice = parseInt(this.selected_voucher.voucher.value);
      const productPrice = parseInt(this.data.price);
      if (voucherPrice <= productPrice) {
        this.Modal = false;
        this.diskon = true;
        if (this.selected_voucher.voucher.type == "nominal") {
          this.potongan = voucherPrice;
          this.total_harga = productPrice - voucherPrice;
        } else {
          let hasilPersen = productPrice * (voucherPrice / 100);
          this.potongan = hasilPersen;
          this.total_harga = productPrice - hasilPersen;
        }
      } else {
        this.displayError({
          message: "Voucher tidak bisa digunakan karena melebihi harga paket",
        });
        return false;
      }
    },
  },
  created() {},
  async mounted() {
    this.getPayment();
    this.getVoucher();
  },
};
</script>
